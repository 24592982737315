import { Component, NgModule, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { CrudService } from '../../../shared/services/crud.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as Feather from 'feather-icons';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit { 

  notifications: any;
  notificationBody: string;
  notificationAlert = "primary";
  messageBoxClass : string;
  premissions: boolean = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private crudService: CrudService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.showCurrentNotifications();
    this.premissions = this.adminPermissions();
  }

  adminPermissions(){
    let localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    if(localStorageUserSettings.permissions == "admin") {
      return true;
    } else {
      return false;
    }
  }


  ApplyMessageBoxClass(event){
    this.messageBoxClass = event.target.id;
  }

  showCurrentNotifications() {
    // Show Current Notifications
    this.crudService.read_Notification().subscribe(data => {
      this.notifications = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Body: e.payload.doc.data()['Body'],
          Alert: e.payload.doc.data()['Alert'],
        };
      })
        console.log(this.notifications);
      });
  }

  CreateNotificationRecord() {
    let record = {};
    record['Body'] = this.notificationBody;
    record['Alert'] = this.notificationAlert;

    this.crudService.create_NewNotification(record).then(resp => {
      this.notificationBody = "";
      this.notificationAlert = "";
      // console.log(resp);
    })
    .catch(error => {
      console.log(error);
    });
  }

  EditRecord(record) {
    record.isEdit = true;
    record.EditBody = record.Body;
    record.EditAlert = record.Alert;
  }

  UpdateRecord(recordRow) {
    let record = {};
    record['Body'] = recordRow.EditBody;
    record['Alert'] = recordRow.EditAlert;

    this.crudService.update_Notification(recordRow.id, record);
    recordRow.isEdit = false;
  }

  RemoveRecord(rowID) {
    this.crudService.delete_Notification(rowID);
  }

  ngAfterViewInit() {
    Feather.replace();
  }

}
