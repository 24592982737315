import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Feather from 'feather-icons';

@Component({
  selector: 'app-search',
  template: `
  <form class='searchform' (ngSubmit)='onSubmit(searchVal)' #heroForm='ngForm'>
    <input class='searchform-input' placeholder='Search the web...' aria-label='Search' type='text' name='q' [(ngModel)]='searchVal' id='searchVal'/>
    <i class='searchform-icon' data-feather="search"></i>
    <input class='searchform-button' type='submit' value='search' />
  </form>
  `,
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  searchVal: string = "";
  onSubmit(searchVal){
    window.open("https://www.google.com/search?q=" + searchVal + "", '_blank');
    this.searchVal = '';
  }

  ngAfterViewInit() {
    Feather.replace();
  }

}




