import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  template: `
  <div class='main-menu-wrapper'>
  <ul class='main-menu'>
      <li>
        <a href='https://www.bhsf.co.uk/' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/bhsf.svg' loading="lazy" alt="BHSF"></div>
          <span class='main-menu__title'>BHSF</span>
        </a>
      </li>
      <li>
        <a href='https://selfserve.cpfoods.co.uk/Login.aspx' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/carval.svg' loading='lazy' alt='Carval'></div>
          <span class='main-menu__title'>Carval</span>
        </a>
      </li>
      <li>
        <a href='https://app.expensein.com/signin?returnUrl=%2Fmyexpenses' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/expensein.svg' loading='lazy' alt='ExpenseIn'></div>
          <span class='main-menu__title'>ExpenseIn</span>
        </a>
      </li>
      <li>
        <a href='https://remote.cpfoods.co.uk/RDWeb/Pages/en-US/login.aspx?ReturnUrl=default.aspx' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/remote.svg' loading='lazy' alt='Remote'></div>
          <span class='main-menu__title'>Remote</span>
        </a>
      </li>
      <li>
        <a href='https://cpfoodsuk.my.salesforce.com' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/salesforce.svg' loading='lazy' alt='SalesForce'></div>
          <span class='main-menu__title'>SalesForce</span>
        </a>
      </li>
      <li>
        <a href='https://cpfukltd.sharepoint.com/sites/TFFSharepoint' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/sharepoint.svg' loading='lazy' alt='SharePoint'></div>
          <span class='main-menu__title'>SharePoint</span>
        </a>
      </li>
      <li>
        <a href='https://app.smartsheet.com/b/home' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/smartsheet.svg' loading='lazy' alt='SmartSheet'></div>
          <span class='main-menu__title'>SmartSheet</span>
        </a>
      </li>
      <li>
        <a href='https://outlook.office.com/' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/webmail.svg' loading='lazy' alt='WebMail'></div>
          <span class='main-menu__title'>WebMail</span>
        </a>
      </li>
      <li>
        <a href='https://www.thefoodfellas.co.uk' target='_blank'>
          <div class='main-menu__icon'><img src='assets/img/menu/website.svg' loading='lazy' alt='Website'></div>
          <span class='main-menu__title'>Website</span>
        </a>
      </li>
  </ul>
  </div>
  `,
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
