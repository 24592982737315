import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time',
  template: `<div class='time'>
              <time class='time-clock'>{{ now | date:'shortTime' }}</time>
              <time class='time-date'>{{ now | date }} </time>
            </div>`,
  // templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
  now:number;

  constructor() {
      setInterval(() => {
        this.now = Date.now();
      }, 1);
  }
 
  ngOnInit(): void { }

}
