
<div class="container"> 

    <div class="header">
        <img class="logo" routerLink="/dashboard" src="assets/img/ff-logo-back.svg" alt="Go Back - TheFoodFellas">
        <div type="button" class="signOut" (click)="authService.SignOut()">
            <i class='log-out-icon' data-feather="log-out"></i> Sign Out
        </div>
    </div> 

    <div class="wrapper" *ngIf="premissions; else noPermisions">
        
  
        <div class="controlls notifications-admin notifications">
          <h1>Notifications</h1>

            <label for="message">Message:</label>
            <textarea id="message" [(ngModel)]="notificationBody" [class]="messageBoxClass"></textarea>

            <p class="choise">
              <span>
                <input type="radio" value="primary" id="primary" name="alert" (click)="ApplyMessageBoxClass($event)" [(ngModel)]="notificationAlert" [checked]="true">
                <label for="primary">Primary</label>
              </span>

              <span>
                <input type="radio" value="danger" id="danger" name="alert" (click)="ApplyMessageBoxClass($event)" [(ngModel)]="notificationAlert"> 
                <label for="danger">Danger</label>
              </span>

              <span>
                <input type="radio" value="warning" id="warning" name="alert" (click)="ApplyMessageBoxClass($event)" [(ngModel)]="notificationAlert"> 
                <label for="warning">Warning</label>
              </span>

              <span>
                <input type="radio" value="dark" id="dark" name="alert" (click)="ApplyMessageBoxClass($event)" [(ngModel)]="notificationAlert">
                <label for="dark">Dark</label>
              </span>

            </p>
            <button type="button" (click)="CreateNotificationRecord()" [disabled]="!notificationBody" class="btn secondary-button">
            + Create Notification</button>
        </div>
  
        <div class="notifications">
            <div class="" *ngFor="let item of notifications">
                <div class="item" [ngClass]="item.Alert" *ngIf="!item.isEdit; else elseBlock">
                    {{item.Body}}
                    <div class="controls">
                      <div class="btn-small primary-button" (click)="EditRecord(item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                        Edit</div>
                      <div class="btn-small danger-button" (click)="RemoveRecord(item.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete</div>
                    </div>
                </div>
 
                <ng-template #elseBlock>
                    <div class="controlls notifications-admin update">
                      <h5>Edit</h5>

                      <textarea [(ngModel)]="item.EditBody" id="editmassage" placeholder="Edit Message" [class]="messageBoxClass"></textarea>
                      <!-- <input type="text" class="form-control" [(ngModel)]="item.EditAlert" placeholder="Edit Alert"> -->

                      <div class="controls">
                        <div class="btn-small primary-button" (click)="UpdateRecord(item)">Update</div>
                        <div class="btn-small danger-button" (click)="item.isEdit = false">Cancel</div>
                      </div>
                      
                    </div>
                </ng-template>

            </div>
        </div>        


    </div>

    <ng-template #noPermisions><h1>You don't have sufficient permissions.</h1></ng-template>

</div>

