export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBXYJEVsDEUIGZ_5774IqFzBz1qgvJmc90",
    authDomain: "ff-intranet-production.firebaseapp.com",
    databaseURL: "https://ff-intranet-production.firebaseio.com",
    projectId: "ff-intranet-production",
    storageBucket: "ff-intranet-production.appspot.com",
    messagingSenderId: "615572757482",
    appId: "1:615572757482:web:65ced6ac9af07ab3923093"
  }
};


