<div class="login">
  <div class="signin">
    <img src="assets/img/logo-svg.svg" class="login-logo" alt="FF Intranet">
    <div class="signin__container">
      <h1>Login</h1>
      <p>Welcome the Intranet.</p>

      <button type="button" class="microsoftBtn" (click)="authService.MicrosoftAuth()">
        Log in with Microsoft Azure
      </button>

      <p class="problems">Having problems? Contact your System Administrator</p>
    </div>
  </div>
</div>