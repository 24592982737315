import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  constructor( 
    public authService: AuthService,
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    public router: Router,
    ) { }

  ngOnInit(): void {

    // Redirect if already logged in
    this.afAuth.authState.subscribe(user => {
      if (user) {
        // this.router.navigate(['dashboard']);
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard']);
          setTimeout(() => {
            this.router.navigated = false;
            this.router.navigate(['/dashboard']);
            }, 1000);
        });
      }
    })

  }

}
