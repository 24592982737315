import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AdminComponent } from './components/pages/admin/admin.component';

// Widgets
import { TimeComponent } from './components/widgets/time/time.component';
import { SearchComponent } from './components/widgets/search/search.component';
import { MainMenuComponent } from './components/widgets/main-menu/main-menu.component';
import { NotificationsComponent } from './components/widgets/notifications/notifications.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from 'node_modules/@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Auth service
import { AuthService } from "./shared/services/auth.service";

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    TimeComponent,
    SearchComponent,
    MainMenuComponent,
    NotificationsComponent,
    AdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase), // Initialize
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireDatabaseModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {}
