import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from "../services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private firestore: AngularFirestore,
    public authService: AuthService,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
  ) {}


  update_Background(recordID,record){
    this.firestore.doc('users/' + recordID).update(record).then(function() {
      alert("Background successfully updated!");
    })
    .catch(function(error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
  }
  
  create_NewNotification(record) {
    return this.firestore.collection('notifications').add(record);
  }

  read_Notification() {
    return this.firestore.collection('notifications').snapshotChanges();
  }

  update_Notification(recordID,record){
    this.firestore.doc('notifications/' + recordID).update(record);
  }

  delete_Notification(record_id) {
    this.firestore.doc('notifications/' + record_id).delete();
  }



}
