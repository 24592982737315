import { Component, NgModule, OnInit, NgZone, Input } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { CrudService } from '../../../shared/services/crud.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ]),
  ]
})

export class DashboardComponent implements OnInit {

  // PopUp
  customisePopUpDiv:boolean = false;
  smartsheetPopUpDiv:boolean = false;

  // Upload
  displayUploadedImage;
  downloadURL: Observable<string>;
  percentage: Observable<number>;
  userUid: Observable<string>;

  localStorageUserData: any;
  premissions: boolean = false;

  @Input() background = 'default.jpg'; 

  bkUrl = {};  
  
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public crudService: CrudService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {}



  // userData: any;
  ngOnInit(): void {
    this.getUserData()
    .then(()=>{
      this.bkUrl = this.getBkUrl();
      this.premissions = this.adminPermissions();
    })
    .catch(err => console.log(err));
  }

  backgroundImages = [
    {
      "name" : "Mini Chicken Fillets",
      "imageSource":"default.jpg",
      "thumbnail": "default.jpg"
    },
    {
      "name" : "Background 1",
      "imageSource":"image-1.jpg",
      "thumbnail": "image-1.jpg"
    },
    {
      "name" : "Background 2",
      "imageSource":"image-2.jpg",
      "thumbnail": "image-2.jpg"
    },
    {
      "name" : "Background 3",
      "imageSource":"image-3.jpg",
      "thumbnail": "image-3.jpg"
    },
    {
      "name" : "Background 4",
      "imageSource":"image-4.jpg",
      "thumbnail": "image-4.jpg"
    },
    {
      "name" : "Background 5",
      "imageSource":"image-5.jpg",
      "thumbnail": "image-5.jpg"
    },
    {
      "name" : "Background 6",
      "imageSource":"image-6.jpg",
      "thumbnail": "image-6.jpg"
    },
    {
      "name" : "Background 7",
      "imageSource":"image-7.jpg",
      "thumbnail": "image-7.jpg"
    },
    {
      "name" : "Background 8",
      "imageSource":"image-8.jpg",
      "thumbnail": "image-8.jpg"
    },
    {
      "name" : "Background 9",
      "imageSource":"image-9.jpg",
      "thumbnail": "image-9.jpg"
    },
    {
      "name" : "Photo by Marc Linnemann on Unsplash",
      "location" : "Poon Hill, Histan Mandali, Nepal",
      "imageSource":"image-10.jpg",
      "thumbnail": "image-10.jpg"
    },
    {
      "name" : "Photo ",
      "imageSource":"image-11.jpg",
      "thumbnail": "image-11.jpg"
    },
    {
      "name" : "Photo by Derek Thomson on Unsplash",
      "location" : "McWay Falls, United States",
      "imageSource" : "image-12.jpg",
      "thumbnail": "image-12.jpg"
    },
    {
      "name" : "Photo by Giammarco Boscaro on Unsplash",
      "location" : "The Shard, London, United Kingdom",
      "imageSource" : "image-13.jpg",
      "thumbnail": "image-13.jpg"
    },
    {
      "name" : "Photographer",
      "location" : "",
      "imageSource" : "image-14.jpg",
      "thumbnail": "image-14.jpg"
    },
    {
      "name" : "Dog",
      "location" : "",
      "imageSource" : "image-15.jpg",
      "thumbnail": "image-15.jpg"
    }
  ];

  formsList = [
    {
      "title" : "New Product Setup Initiation",
      "url":"https://app.smartsheet.com/b/form/79c18f99483a4ac894e735e33c741416",
    },
    {
      "title" : "New Supplier Pre-Approval",
      "url": "https://app.smartsheet.com/b/form/267d58b1dda549e7a13c253db5db05c5"
    },
    {
      "title" : "New Service Supplier Form",
      "url": "https://app.smartsheet.com/b/form/a8c087a5a002414fb42eabc0d771b7d5"
    },
    {
      "title" : "FoodFellas Air Travel Information",
      "url": "https://app.smartsheet.com/b/form/be9516bee4024d35a83ccdda69481355"
    }
  ];



  getBkUrl() {
    let localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    if(localStorageUserSettings.backgroundUrl == null || localStorageUserSettings.backgroundUrl == "") {
      const styles = { 'background-image': 'url(assets/img/background/' + this.background + ')'};
      return styles;
    } else {
      this.background = localStorageUserSettings.backgroundUrl;
      const styles = { 'background-image': 'url(assets/img/background/' + this.background + ')' };
      return styles;
    }
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      this.localStorageUserData = JSON.parse(localStorage.getItem('user'));
      const userDataByID = this.firestore.collection("users").doc(this.localStorageUserData.uid);
      userDataByID.get().toPromise().then(function(doc) {
          if (doc.exists) {
              localStorage.setItem('userSettings', JSON.stringify(doc.data()));
              resolve('We have the user data');
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              reject('Error: Something went wrong');
          }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    })
  }


  getBackgroundID(event){
    const img = document.querySelector<HTMLElement>('.containerBackground')!;
    img.style.backgroundImage = `url('assets/img/background/${event.currentTarget.id}')`;

    // On Click Save to localstorage
    let localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    localStorageUserSettings.backgroundUrl = event.currentTarget.id;
    localStorage.setItem('userSettings', JSON.stringify(localStorageUserSettings));
    JSON.parse(localStorage.getItem('userSettings'));
  }
  
  updateBackgroundRecord() {
    let record = {};
    let localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    record['backgroundUrl'] = localStorageUserSettings.backgroundUrl;
    let recordID = localStorageUserSettings.uid;
    this.crudService.update_Background(recordID, record);
  }

  adminPermissions(){
    let localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    if(localStorageUserSettings.permissions == "admin") {
      return true;
    } else {
      return false;
    }
  }
 
  customisePopup() {
    return this.customisePopUpDiv = !this.customisePopUpDiv;
  }

  smartsheetPopup() {
    return this.smartsheetPopUpDiv = !this.smartsheetPopUpDiv;
  }

  // Upload Image
  onFileSelected(event) {
    let userUid = this.authService.userData.uid;

    const file = event.target.files[0];
    const filePath = `BackgroundImages/${userUid}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`BackgroundImages/${userUid}`, file);
    // Progress monitoring
    this.percentage = task.percentageChanges();

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.displayUploadedImage = url;
            }
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log(url);
        }
      });
  }

}
