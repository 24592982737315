import { Component, OnInit, Input } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  // @Input() addNotificationDiv:boolean;
  notifications:any;

  constructor(private crudService: CrudService) {}
  ngOnInit(): void {
    this.showCurrentNotifications();
  }

  showCurrentNotifications() {
    // Show Current Notifications
    this.crudService.read_Notification().subscribe(data => {
      this.notifications = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Body: e.payload.doc.data()['Body'],
          Alert: e.payload.doc.data()['Alert'],
        };
      })
        console.log(this.notifications);
      });
  }
  
  // addNotificationPopUp() {
  //   this.addNotificationDiv = !this.addNotificationDiv;
  // }

}
