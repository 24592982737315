<div class="container containerBackground" [ngStyle]="bkUrl" [@fadeInOut]>

    <div class="header">
        <img class="logo" src="assets/img/logo.svg" alt="FoodFellas">
        <div type="button" class="signOut" (click)="authService.SignOut()">
            <i class='log-out-icon' data-feather="log-out"></i> Sign Out
        </div>
    </div>

    <div class="widgets">
        <app-time></app-time>
        <app-search></app-search>
        <app-main-menu></app-main-menu>
        <app-notifications></app-notifications>        
    </div>

    <div class="bottom-toolbar">

        <ul class="bottom-toolbar__links">
            <li>
                <div class="toolbar__links-button" (click)="smartsheetPopup()">
                    <div class="toolbar__links-button">
                        <i class='toolbar__links-icon clipboard-icon' data-feather="clipboard"></i>
                        <span>Smartsheet Forms</span>
                    </div>
                </div>
            </li>
        </ul>

        <ul class="bottom-toolbar__links">
            <li *ngIf="premissions">
                <div class="toolbar__links-button" routerLink="/admin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    <span>Administrator</span>
                </div>
            </li> 
            <li>
                <div class="toolbar__links-button" (click)="customisePopup()">
                    <i class='toolbar__links-icon sliders-icon' data-feather="sliders"></i>
                    <span>Customise</span>
                </div>
            </li>
        </ul>
      </div>

</div>


<div class="customise-popup popup-wrapper" *ngIf="customisePopUpDiv" [ngClass]="{'fade-in' : customisePopUpDiv}">
  <div class="close-overlay" (click)="customisePopup()"></div>
  <div class="customise-popup-wrapper">
      <div class="customise-popup__menu">
          <h2>Customise Dashboard</h2>
          <ul>
              <li><i class='customise-popup__menu-icon image-icon' data-feather="image"></i> Background Image</li>
          </ul>
      </div>
      <div class="customise-popup__details">
          <div class="closeModalX" (click)="customisePopup()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
          <div class="background-settings">

            <ul class="background-settings__list">
                <li (click)="getBackgroundID($event)" *ngFor="let item of backgroundImages" id="{{item.imageSource}}">
                    <img src="assets/img/thumbnails/{{item.thumbnail}}" alt="{{item.name}}">
                </li>
            </ul>

            <div class="btn secondary-button" (click)="updateBackgroundRecord()">Save</div>
            <div class="btn discard-button" onClick="window.location.href=window.location.href">Discard</div>

          </div>
      </div>
  </div>
</div>

<div class="smartsheet-forms-popup popup-wrapper" *ngIf="smartsheetPopUpDiv" [ngClass]="{'fade-in' : smartsheetPopUpDiv}">
    <div class="close-overlay" (click)="smartsheetPopup()"></div>
    <div class="smartsheet-forms-popup-wrapper">
        <div class="smartsheet-forms-popup__menu">
            <h2>Smartsheet Forms</h2>
        </div>
        <div class="smartsheet-forms-popup__details">
            <div class="closeModalX" (click)="smartsheetPopup()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>

              <ul class="form__list">
                <li *ngFor="let item of formsList" id="{{item.title}}">
                    <a href="{{item.url}}" target="_blank">
                    <img src="assets/img/smartsheet-forms.webp" alt="{{item.title}}">
                    {{item.title}}
                    </a>
                </li>
              </ul>
        </div>
    </div>
  </div>